import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import * as fromActions from '../actions/collaborator.actions';

export const featureKey = 'collaborator';

export interface CollaboratorState {
    // control
    isLoading: boolean;
    hasError: boolean;
    isSuccessful: boolean;
    error: any;
    // data
    payload: any;
    types?: any;
}

export const initialState: CollaboratorState = {
    isLoading: false,
    hasError: false,
    isSuccessful: false,
    error: null,
    payload: null,
    types: null
};

const CollaboratorsReducer: ActionReducer<CollaboratorState> = createReducer(
    initialState,
    // On Begin Actions
    on(fromActions.allCollaboratorsbyEnterprise,
        (state): CollaboratorState => ({
            ...state,
            isLoading: true,
            hasError: false,
            isSuccessful: false,
            error: null,
            types: null
        })),
    on(fromActions.addCollaboratorsbyEnterprise,
        (state): CollaboratorState => ({
            ...state,
            isLoading: true,
            hasError: false,
            isSuccessful: false,
            error: null
        })),

    // ON Success Actions
    on(fromActions.allCollaboratorsbyEnterpriseSuccess,
        (state, action): CollaboratorState => {
            return {
                ...state,
                isLoading: false,
                hasError: false,
                error: null,
                isSuccessful: true,
                payload: action.payload,
                types: action.types
            }
        }),
    on(fromActions.addCollaboratorsbyEnterpriseSuccess,
        (state, action): CollaboratorState => {
            // console.log(action);
            return {
                ...state,
                isLoading: false,
                hasError: false,
                error: null,
                isSuccessful: true,
                payload: action.payload
            }
        }),

    on(fromActions.addCollaboratorsbyEnterpriseFail,
        (state, action): CollaboratorState => ({
            ...initialState,
            error: action.errors,
            hasError: true,
            isSuccessful: false,
            isLoading: false,
    })),


    // ON Fail Actions
    on(fromActions.allCollaboratorsbyEnterpriseFail,
        (state, action): CollaboratorState => ({
            ...initialState,
            error: action.error,
            hasError: true,
            isSuccessful: false,
            isLoading: false,
        })),
        
);

// tslint:disable-next-line: typedef
export function reducer(state: CollaboratorState | undefined, action: Action) {
    return CollaboratorsReducer(state, action);
}
export const isLoading = (state: CollaboratorState) => state.isLoading;
export const error = (state: CollaboratorState) => state.error;
export const payload = (state: CollaboratorState) => state.payload;
