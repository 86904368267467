import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import * as fromActions from '../actions/login.actions';

export const featureKey = 'auth';

export interface LoginState {
  // control
  isLoading: boolean;
  hasError: boolean;
  isSuccessful: boolean;
  error: any;
  // data
  payload: any;
}

export const initialState: LoginState = {
  isLoading: false,
  hasError: false,
  isSuccessful: false,
  error: null,
  payload: null,
};

const loginReducer: ActionReducer<LoginState> = createReducer(
  initialState,
  // On Begin Actions
  on(
    fromActions.login,
    fromActions.generateToken,
    fromActions.signUp,
    fromActions.validateToken,
    fromActions.changePassword,
    (state): LoginState => ({
      ...state,
      isLoading: true,
      hasError: false,
      isSuccessful: false,
      error: null,
    })
  ),

  // ON Success Actions
  on(
    fromActions.loginSuccess,
    fromActions.generateTokenSuccess,
    fromActions.signUpSuccess,
    fromActions.validateTokenSuccess,
    fromActions.changePasswordSuccess,
    (state, action): LoginState => ({
      ...state,
      isLoading: false,
      hasError: false,
      error: null,
      payload: action.payload,
    })
  ),

  // ON Fail Actions
  on(
    fromActions.loginFail,
    fromActions.generateTokenFail,
    fromActions.signUpFail,
    fromActions.validateTokenFail,
    fromActions.changePasswordFail,
    (state, action): LoginState => ({
      ...initialState,
      error: action.error,
      hasError: true,
      isSuccessful: false,
      isLoading: false,
    })
  )
);

// tslint:disable-next-line: typedef
export function reducer(state: LoginState | undefined, action: Action) {
  return loginReducer(state, action);
}
export const isLoading = (state: LoginState) => state.isLoading;
export const error = (state: LoginState) => state.error;
export const payload = (state: LoginState) => state.payload;
