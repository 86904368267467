import { ActionReducerMap, MetaReducer } from '@ngrx/store';

import * as fromLogin from './login.reducer';
import * as fromCollaborator from './collaborator.reducer';

export interface AppState {
    login: fromLogin.LoginState;
    collaborator: fromCollaborator.CollaboratorState;
}

export const reducers: ActionReducerMap<AppState> = {
    login: fromLogin.reducer,
    collaborator: fromCollaborator.reducer
};

export const metaReducers: MetaReducer<AppState>[] = [];
