import { createAction, props } from '@ngrx/store';

export enum ActionTypes {
  LOGIN = '[Login] login',
  LOGIN_SUCCESS = '[Login] login success',
  LOGIN_FAIL = '[Login] login fail',
  GENERATE_TOKEN = '[Login] generateToken',
  GENERATE_TOKEN_SUCCESS = '[Login] generateToken success',
  GENERATE_TOKEN_FAIL = '[Login] generateToken fail',
  SIGNUP = '[Login] signUp',
  SIGNUP_SUCCESS = '[Login] signUp success',
  SIGNUP_FAIL = '[Login] signUp fail',
  VALIDATE_TOKEN = '[Login] validateToken',
  VALIDATE_TOKEN_SUCCESS = '[Login] validateToken success',
  VALIDATE_TOKEN_FAIL = '[Login] validateToken fail',
  CHANGE_PASSWORD = '[Login] changePassword',
  CHANGE_PASSWORD_SUCCESS = '[Login] changePassword success',
  CHANGE_PASSWORD_FAIL = '[Login] changePassword fail',
}

// GET ALL
export const login = createAction(
  ActionTypes.LOGIN,
  props<{ email: string; password: string }>()
);

export const loginSuccess = createAction(
  ActionTypes.LOGIN_SUCCESS,
  props<{ payload: any }>()
);

export const loginFail = createAction(
  ActionTypes.LOGIN_FAIL,
  props<{ error: any }>()
);

export const signUp = createAction(
  ActionTypes.SIGNUP,
  props<{ params: any }>()
);

export const signUpSuccess = createAction(
  ActionTypes.SIGNUP_SUCCESS,
  props<{ payload: any }>()
);

export const signUpFail = createAction(
  ActionTypes.SIGNUP_FAIL,
  props<{ error: any }>()
);

export const generateToken = createAction(
  ActionTypes.GENERATE_TOKEN,
  props<{ email: string }>()
);

export const generateTokenSuccess = createAction(
  ActionTypes.GENERATE_TOKEN_SUCCESS,
  props<{ payload: any }>()
);

export const generateTokenFail = createAction(
  ActionTypes.GENERATE_TOKEN_FAIL,
  props<{ error: any }>()
);

export const validateToken = createAction(
  ActionTypes.VALIDATE_TOKEN,
  props<{ token: string }>()
);

export const validateTokenSuccess = createAction(
  ActionTypes.VALIDATE_TOKEN_SUCCESS,
  props<{ payload: any }>()
);

export const validateTokenFail = createAction(
  ActionTypes.VALIDATE_TOKEN_FAIL,
  props<{ error: any }>()
);

export const changePassword = createAction(
  ActionTypes.CHANGE_PASSWORD,
  props<{ data: any }>()
);

export const changePasswordSuccess = createAction(
  ActionTypes.CHANGE_PASSWORD_SUCCESS,
  props<{ payload: any }>()
);

export const changePasswordFail = createAction(
  ActionTypes.CHANGE_PASSWORD_FAIL,
  props<{ error: any }>()
);
