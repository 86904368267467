import { createAction, props } from '@ngrx/store';

export enum ActionTypes {
    ALL_COLLABORATORS_ENTERPRISE = '[Collaborators] allCollaboratorsbyEnterprise',
    ALL_COLLABORATORS_ENTERPRISE_SUCCESS = '[Collaborators] allCollaboratorsbyEnterprise success',
    ALL_COLLABORATORS_ENTERPRISE_FAIL = '[Collaborators] allCollaboratorsbyEnterprise fail',
    ADD_COLLABORATORS_ENTERPRISE = '[Collaborators] addCollaboratorsbyEnterprise',
    ADD_COLLABORATORS_ENTERPRISE_SUCCESS = '[Collaborators] addCollaboratorsbyEnterprise success',
    ADD_COLLABORATORS_ENTERPRISE_FAIL = '[Collaborators] addCollaboratorsbyEnterprise fail',
}

// GET ALL
export const allCollaboratorsbyEnterprise = createAction(
    ActionTypes.ALL_COLLABORATORS_ENTERPRISE,
    props<{ enterpriseId: string, types: any }>()
);

export const allCollaboratorsbyEnterpriseSuccess = createAction(
    ActionTypes.ALL_COLLABORATORS_ENTERPRISE_SUCCESS,
    props<{ payload: any, types: any }>()
);

export const allCollaboratorsbyEnterpriseFail = createAction(
    ActionTypes.ALL_COLLABORATORS_ENTERPRISE_FAIL,
    props<{ error: any }>()
);

export const addCollaboratorsbyEnterprise = createAction(
    ActionTypes.ADD_COLLABORATORS_ENTERPRISE,
    props<{ enterpriseId: string, catchmetoken: string, userId: string }>()
);

export const addCollaboratorsbyEnterpriseSuccess = createAction(
    ActionTypes.ADD_COLLABORATORS_ENTERPRISE_SUCCESS,
    props<{ payload: any }>()
);

export const addCollaboratorsbyEnterpriseFail = createAction(
    ActionTypes.ADD_COLLABORATORS_ENTERPRISE_FAIL,
    props<{ errors: any }>()
);